<template>
  <v-app>
    <v-main>
      <v-snackbar v-model="showNotice">{{ notice }}</v-snackbar>
      <v-row no-gutters justify="center" class="my-16">
        <v-img src="/banner.png" width="auto" height="100"></v-img>
      </v-row>
      <v-row no-gutters justify="center" class="my-16">
        <v-btn color="primary" prepend-icon="mdi-account-circle" @click="onLogin">
          钉钉登录
        </v-btn>
      </v-row>
    </v-main>
  </v-app>
</template>

<script setup lang="ts">
const appConfig = useAppConfig()

const notice = ref('')
const showNotice = computed({
  get() {
    return Boolean(notice.value)
  },
  set(v) {
    notice.value = ''
  },
})

function onLogin() {
  navigateTo('https://login.dingtalk.com/oauth2/auth?' + new URLSearchParams({
    redirect_uri: appConfig.dingTalkAuth + document.location.pathname +
      // dingtalk OpenAPI bug: redirect_url cannot contains '&'
      document.location.search.replaceAll('&', '%26'),
    response_type: 'code',
    client_id: appConfig.appKey,
    scope: 'openid',
    state: Math.random() * 10000000 + '',
    prompt: 'consent',
  }).toString(), { external: true })
}

onMounted(async ()=>{
  try {
    await getUserInfo()
  } catch (e) {
    return
  }
  navigateTo("./dashboard")
})
</script>
